import React from 'react'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'
import {
  Container,
  Title,
  Content,
  Button,
  Grid,
  Card
} from '../components/styled'
import Quote from '../components/Quote'
import Contact from '../components/Contact'

export default ({ data }) => {
  //console.log(data)
  return (
  <Layout>
    <SEO
      title="Leistungen Online-Marketing & Webentwicklung - Projectival"
      description="► Leistungen: ✓ Website-Konzept ✓ UX/SEO-Audit ✓ Landingpage ✓ Pagespeed-Optimierung ✓ Leadgenerierung ✓ Webentwicklung ► Leistungen ansehen"
    />
    <Container>
      <Container.Inner>
        <Title as="h1">Leistungen</Title>
        <Grid className="nb5 flex-wrap-l">
          {[
            /*{
              permalink: '/ux-seo-audit/',
              title: 'UX/SEO-Audit',
              description: '<p>Ein Audit in den Bereichen User Experience (UX) und Suchmaschinenoptimierung (SEO) liefert Ihnen konkrete Maßnahmen, mit denen Sie Schritt für Schritt Ihre Webseite oder Landingpage verbessern und mehr Umsatz im Web erzielen.</p>',
            },*/
            {
              permalink: '/landingpage/',
              title: 'Landingpage',
              description: '<p>Eine gute Landingpage weckt das Interesse Ihrer Zielgruppe, schafft Vertrauen bei potenziellen Kunden und wandelt jeden Euro, den Sie in Online-Kampagnen stecken, in mehr Geld.</p>',
            },
            {
              permalink: '/website-konzept/',
              title: 'Website-Konzept',
              description: '<p>Mit einem Konzept, das Zielgruppe und Unternehmensziele, wie User Experience (UX) und Conversion Rate Optimierung (CRO) berücksichtigt, schaffen Sie die Basis für den Erfolg Ihrer neuen Webseite oder Landingpage.</p>',
            },
            {
              permalink: '/leadgenerierung/',
              title: 'Leadgenerierung',
              description: '<p>Smarte Marketer wissen: Der Aufbau eigener E-Mail-Verteiler lohnt, ist aber nicht immer leicht. Eine zielgerichtete Leadgenerierung, die potenzielle Kunden für Ihr Produkt oder Ihre Dienstleistung anspricht, ist genau der richtige Weg zu mehr Kunden.</p>',
            },
            {
              permalink: '/pagespeed-optimierung/',
              title: 'Pagespeed-Optimierung',
              description: '<p>Durch Pagespeed-Optimierung machen Sie Ihre Webseite oder Landingpage um wertvolle Sekunden schneller und erhöhen damit die Wahrscheinlichkeit, einen potenziellen Kunden zu gewinnen, bevor er abspringt.</p>',
            },
            /*{
              permalink: '/webentwicklung/',
              title: 'Webentwicklung',
              description: '<p>Als verlässlicher Partner code ich nicht nur Webseiten, HTML-Mails oder webbasierte Tools, sondern möchte, das Sie mit dem Ergebnis erfolgreich sind.</p>',
            },*/
          ].map((item, i) =>
            <Grid.Col className="mb5 w-50-l" key={`item-${i}`}>
              <Card as={Link} to={item.permalink}>
                <Card.Inner>
                  <Title small>{item.title}</Title>
                  <Content dangerouslySetInnerHTML={{__html: item.description}} />
                </Card.Inner>
              </Card>
            </Grid.Col>
          )}
        </Grid>
      </Container.Inner>
    </Container>
   
  </Layout>
)}
